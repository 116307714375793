import TaggedText from 'pixi-tagged-text';
import { IPointData } from 'pixi.js';

import { AutoResizeTextStyle } from '../slotMachine/components/autoResizeText';
import { introDescriptionStylesInfo, introTitleStylesInfo } from '../slotMachine/introScreen/Carousel/styles';

import { SpineInterface } from './spine.generated';

type TextStyleSet = (typeof TaggedText)['defaultStyles'];

export const INTRO_CAROUSEL_WIDTH = 1200;
export const INTRO_CAROUSEL_HEIGHT = 978;
export const INTRO_FRAME_SPINE_HEIGHT = 600;

export interface ISlideText {
  i18nKey: string;
  textStyle: TextStyleSet | AutoResizeTextStyle;
  position: IPointData;
}

export interface ISlideImage {
  spineName: keyof SpineInterface;
  animationName: string;
  position: IPointData;

  scale: number;
}
export interface ISlide {
  textInfos: readonly ISlideText[];
  spine: ISlideImage | undefined;
}

export const INTRO_CONTAINER_LANDSCAPE_SCALE = 864 / 1200;
export const introContents = [
  {
    textInfos: [
      { i18nKey: 'introTitle.page1.title', textStyle: introTitleStylesInfo, position: { x: 0, y: 60 } },
      { i18nKey: 'introTitle.page1.tagged', textStyle: introDescriptionStylesInfo, position: { x: 0, y: 715 } },
    ] as const,
    spine: {
      spineName: 'intro',
      animationName: '1',
      position: { x: 0, y: 191 + 518 / 2 },
      scale: 1,
    },
  },
  {
    textInfos: [
      { i18nKey: 'introTitle.page2.title', textStyle: introTitleStylesInfo, position: { x: 0, y: 60 } },
      { i18nKey: 'introTitle.page2.tagged', textStyle: introDescriptionStylesInfo, position: { x: 0, y: 735 } },
    ] as const,
    spine: {
      spineName: 'intro',
      animationName: '2',
      position: { x: 0, y: 159 + 588 / 2 },
      scale: 1,
    },
  },
  {
    textInfos: [
      { i18nKey: 'introTitle.page3.title', textStyle: introTitleStylesInfo, position: { x: 0, y: 60 } },
      { i18nKey: 'introTitle.page3.tagged', textStyle: introDescriptionStylesInfo, position: { x: 0, y: 755 } },
    ] as const,
    spine: {
      spineName: 'intro',
      animationName: '3',
      position: { x: 0, y: 196 + 566 / 2 },
      scale: 1,
    },
  },
  {
    textInfos: [
      { i18nKey: 'introTitle.page4.title', textStyle: introTitleStylesInfo, position: { x: 0, y: 60 } },
      { i18nKey: 'introTitle.page4.tagged', textStyle: introDescriptionStylesInfo, position: { x: 0, y: 765 } },
    ] as const,
    spine: {
      spineName: 'intro',
      animationName: '4',
      position: { x: 0, y: 196 + 580 / 2 },
      scale: 1,
    },
  },
] as const satisfies readonly ISlide[];

export const INTRO_OUTER_TEXT_POSITION: IPointData = {
  x: 80 - INTRO_CAROUSEL_WIDTH / 2,
  y: 678,
};

export const INTRO_DOT_POSITION_Y = INTRO_CAROUSEL_HEIGHT + 10;
