import * as PIXI from 'pixi.js';

import { Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';

export const fishingChallengeMeterTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 34,
  lineJoin: 'round',
  fontWeight: 'bold',
  fill: '#FFFFFF',
  align: 'left',
  maxWidth: 210,
  maxHeight: 46,
};

export const fishingChallengeMeterPortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 45,
  lineJoin: 'round',
  fontWeight: 'bold',
  fill: '#FFFFFF',
  align: 'left',
  maxWidth: 270,
  maxHeight: 62,
};

export const fishingChallengeRoundTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 115,
  lineJoin: 'round',
  fontWeight: 'bold',
  fill: '#FF8C22',
  align: 'center',
  maxWidth: 615,
  stroke: '#FFFFFF',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowAngle: Math.atan2(10, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(10 * 10),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const fishingChallengeRoundPortraitTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingChallengeRoundTextStyles,
  fontSize: 120,
};

export const gambleSelectTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 59,
  fill: ['#FF0000', '#FF4D19', '#FFED4D'],
  fillGradientStops: [0, 0.46, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4.5, 0),
  dropShadowBlur: 3.6,
  dropShadowDistance: Math.sqrt(4.5 * 4.5),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const gambleSelectTitleTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 900,
  fontSize: 62,
  fill: '#FFAB00',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const gambleSelectRankTitleTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 43,
  fill: '#FF2408',
  fontWeight: 'bold',
  align: 'center',
  lineJoin: 'round',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowAngle: Math.atan2(10, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(10 * 10),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const gambleSelectRankTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 43,
  fill: '#FFB500',
  fontWeight: 'bold',
  align: 'center',
  lineJoin: 'round',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowAngle: Math.atan2(10, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(10 * 10),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const gambleSelectMultiplierDescTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 35,
  fill: '#0E0E0E',
  fontWeight: 'bold',
  align: 'center',
  lineJoin: 'round',
  stroke: '#FFFFFF',
  strokeThickness: 6,
};
export const gambleSelectMultiplierTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1110,
  fontSize: 75,
  fill: '#FF0101',
  fontWeight: 'bold',
  align: 'center',
  lineJoin: 'round',
  stroke: '#FFFFFF',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.9,
};

export const gambleSelectDescriptionTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1050,
  fontSize: 43,
  fill: '#FFFFFF',
  fontWeight: 'bold',
  align: 'center',
  lineHeight: 55,
};

export const fishingGambleTitleTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1084,
  fontSize: 122,
  fontWeight: 'bold',
  fill: ['#FF0000', '#F9B300'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#000000',
  strokeThickness: 10,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(15, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(15 * 15),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const fishingGambleDistanceTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1084,
  fontSize: 183,
  fontWeight: 'bold',
  fill: ['#C87800', '#E7B600', '#E0CA00'],
  fillGradientStops: [0, 0.56, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#000000',
  strokeThickness: 4,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(5, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(5 * 5),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const fishingGambleLoseTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1084,
  fontSize: 205,
  fontWeight: 'bold',
  fill: ['#A0CDFF', '#010D99'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#000000',
  strokeThickness: 10,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(10, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(10 * 10),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const fishingScalingRankTitleTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 80,
  fontWeight: 'bold',
  fill: '#FF0606',
  align: 'left',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 0,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.8,
};

export const fishingScalingRankTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 90,
  fontWeight: 'bold',
  fill: '#FF9A03',
  align: 'left',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 0,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.8,
};

export const fishingScalingFishNameTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 600,
  fontSize: 80,
  fontWeight: 'bold',
  fill: '#FF0000',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 0,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.8,
};

export const fishingScalingBetTitleTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1200,
  fontSize: 85,
  fontWeight: 'bold',
  align: 'center',
  lineJoin: 'round',
  fill: ['#FF3030', '#8B0002'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  stroke: '#FFFFFF',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 0,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const fishingScalingMultiplierTextStyles: Partial<PIXI.IBitmapTextStyle> = {
  fontName: 'BILLFISH',
  align: 'center',
  fontSize: 480,
  //maxWidth: 1600,
  letterSpacing: -30,
};

export const fishingScalingWinMultiplierPortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1200,
  fontSize: 98,
  fontWeight: 'bold',
  fill: '#FF0505',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const fishingScalingWinMultiplierLandscapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingWinMultiplierPortraitTextStyles,
  maxWidth: 1200,
  fontSize: 200,
  strokeThickness: 14,
};
export const fishingScalingWinValuePortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1200,
  fontSize: 181,
  fontWeight: 'bold',
  fill: ['#FF5C00', '#FFE606'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const fishingScalingWinValueLandscapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingWinValuePortraitTextStyles,
  maxWidth: 1900,
  fontSize: 150,
  strokeThickness: 6,
};
