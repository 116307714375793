import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import ViewContainer from '../components/container';
import { layerFishingPrize, layerFishingPrizeBg } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

class FishPrizeBg extends ViewContainer {
  private bg: TickerSpine<'fc_suc'>;
  private back: TickerSpine<'fc_suc'>;

  private prizeFront: TickerSpine<'fc_suc'>;
  private layout: 'portrait' | 'landscape';

  constructor() {
    super();

    this.layout = 'landscape';

    this.bg = new TickerSpine('fc_suc');
    this.bg.state.setAnimation(0, 'bg', true);

    this.back = new TickerSpine('fc_suc');
    this.back.state.setAnimation(0, 'y_back', true);

    this.prizeFront = new TickerSpine('fc_suc');
    this.prizeFront.state.setAnimation(0, 'bg_front', true);

    this.addChild(this.bg, this.back, this.prizeFront);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.start.bind(this));
    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_END, this.end.bind(this));

    eventManager.addListener(EventTypes.FISHING_SCALING_START, this.onScalingStart.bind(this));

    this.parentLayer = layerFishingPrizeBg;

    this.prizeFront.parentLayer = layerFishingPrize;
    this.prizeFront.zOrder = 20;

    this.visible = false;

    //test
    //this.start('Rank1');
  }

  public start(_fishRank: FishKind) {
    const layout = this.layout === 'portrait' ? 't' : 'y';
    const back = `${layout}_back` as SpineInterface['fc_suc']['animations'];

    this.bg.state.setAnimation(0, 'bg', false);
    this.back.state.setAnimation(0, back, false);
    this.prizeFront.state.setAnimation(0, 'bg_front', false);

    requestAnimationFrame(() => {
      this.visible = true;
    });
  }

  private end() {
    this.visible = false;

    //this.bg.state.setEmptyAnimation(0);
    //this.back.state.setEmptyAnimation(0);
  }

  private onChangeMode(_settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (_settings.mode === GameMode.BASE_GAME) {
      this.visible = false;
    }
  }

  private onScalingStart() {
    this.visible = false;
  }

  private applicationResize = (width: number, height: number): void => {
    this.x = width / 2;
    this.y = height / 2;

    if (width > height) {
      if (this.layout === 'portrait') {
        this.back.state.setAnimation(0, 'y_back', true);
      }
      this.layout = 'landscape';
    } else {
      if (this.layout === 'landscape') {
        this.back.state.setAnimation(0, 't_back', true);
      }
      this.layout = 'portrait';
    }

    const bgSize = { width: 2000, height: 2000 };
    const bgAspectRatio = bgSize.width / bgSize.height;
    const aspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > aspectRatio) {
      scale = height / bgSize.height;
    } else {
      scale = width / bgSize.width;
    }
    this.bg.scale.set(scale);

    this.prizeFront.scale.set(scale);
  };
}

export default FishPrizeBg;
