import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SFX_UI_BetChange: 'SFX_UI_BetChange',
  SFX_UI_Close: 'SFX_UI_Close',
  SFX_UI_General: 'SFX_UI_General',
  SFX_UI_Hover: 'SFX_UI_Hover',
  SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  XT004S_phoenix_sign: 'XT004S_phoenix_sign',
  XT005S_SC1_stop: 'XT005S_SC1_stop',
  XT005S_SC4_stop: 'XT005S_SC4_stop',
  XT005S_SC_longspin_long: 'XT005S_SC_longspin_long',
  XT005S_SC_longspin_middle: 'XT005S_SC_longspin_middle',
  XT005S_SC_longspin_short: 'XT005S_SC_longspin_short',
  XT005S_anticipation_high: 'XT005S_anticipation_high',
  XT005S_anticipation_low: 'XT005S_anticipation_low',
  XT005S_bg_base_loop: 'XT005S_bg_base_loop',
  XT005S_bg_challenge1_loop: 'XT005S_bg_challenge1_loop',
  XT005S_bg_challenge2_loop: 'XT005S_bg_challenge2_loop',
  XT005S_bg_challenge3_loop: 'XT005S_bg_challenge3_loop',
  XT005S_bg_challenge_roulette_loop: 'XT005S_bg_challenge_roulette_loop',
  XT005S_bg_gamble_loop: 'XT005S_bg_gamble_loop',
  XT005S_bg_gotcha_loop: 'XT005S_bg_gotcha_loop',
  XT005S_bg_result_loop: 'XT005S_bg_result_loop',
  XT005S_bg_scaling: 'XT005S_bg_scaling',
  XT005S_bigwin_loop: 'XT005S_bigwin_loop',
  XT005S_challenge_roulette: 'XT005S_challenge_roulette',
  XT005S_challenge_roulette_stop: 'XT005S_challenge_roulette_stop',
  XT005S_challenge_round: 'XT005S_challenge_round',
  XT005S_choice: 'XT005S_choice',
  XT005S_choice_select: 'XT005S_choice_select',
  XT005S_feature_trigger: 'XT005S_feature_trigger',
  XT005S_gamble_aori_fast: 'XT005S_gamble_aori_fast',
  XT005S_gamble_aori_mid: 'XT005S_gamble_aori_mid',
  XT005S_gamble_aori_slow: 'XT005S_gamble_aori_slow',
  XT005S_gamble_indicator_fix: 'XT005S_gamble_indicator_fix',
  XT005S_gamble_indicator_green: 'XT005S_gamble_indicator_green',
  XT005S_gamble_indicator_red: 'XT005S_gamble_indicator_red',
  XT005S_gamble_indicator_white: 'XT005S_gamble_indicator_white',
  XT005S_gamble_lose: 'XT005S_gamble_lose',
  XT005S_gamble_trigger: 'XT005S_gamble_trigger',
  XT005S_gotcha2: 'XT005S_gotcha2',
  XT005S_gotcha3: 'XT005S_gotcha3',
  XT005S_push_buy_feature: 'XT005S_push_buy_feature',
  XT005S_scaling_count_add1: 'XT005S_scaling_count_add1',
  XT005S_scaling_count_add10: 'XT005S_scaling_count_add10',
  XT005S_scaling_count_add2: 'XT005S_scaling_count_add2',
  XT005S_scaling_count_add3: 'XT005S_scaling_count_add3',
  XT005S_scaling_count_add4: 'XT005S_scaling_count_add4',
  XT005S_scaling_count_add5: 'XT005S_scaling_count_add5',
  XT005S_scaling_count_add6: 'XT005S_scaling_count_add6',
  XT005S_scaling_count_add7: 'XT005S_scaling_count_add7',
  XT005S_scaling_count_add8: 'XT005S_scaling_count_add8',
  XT005S_scaling_count_add9: 'XT005S_scaling_count_add9',
  XT005S_scaling_count_base: 'XT005S_scaling_count_base',
  XT005S_scaling_count_end: 'XT005S_scaling_count_end',
  XT005S_spin_loop: 'XT005S_spin_loop',
  XT005S_spin_start: 'XT005S_spin_start',
  XT005S_spin_stop1: 'XT005S_spin_stop1',
  XT005S_spin_stop2: 'XT005S_spin_stop2',
  XT005S_spin_stop3: 'XT005S_spin_stop3',
  XT005S_spin_stop4: 'XT005S_spin_stop4',
  XT005S_spin_stop5: 'XT005S_spin_stop5',
  XT005S_wheel_miss: 'XT005S_wheel_miss',
  XT005S_wheel_nice: 'XT005S_wheel_nice',
  XT005S_wheel_rotate: 'XT005S_wheel_rotate',
  XT005S_wheel_spin_push: 'XT005S_wheel_spin_push',
  XT005S_win_big: 'XT005S_win_big',
  XT005S_win_end: 'XT005S_win_end',
  XT005S_win_epic: 'XT005S_win_epic',
  XT005S_win_great: 'XT005S_win_great',
  XT005S_win_loop: 'XT005S_win_loop',
  XT005S_win_mega: 'XT005S_win_mega',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  SFX_UI_AutoSpin: [0, 320.4761904761905],
  SFX_UI_BetChange: [2000, 32.086167800453595],
  SFX_UI_Close: [4000, 200.6802721088432],
  SFX_UI_General: [6000, 88.34467120181432],
  SFX_UI_Hover: [8000, 151.76870748299365],
  SFX_UI_MaxBet: [10000, 122.92517006802761],
  SFX_UI_MenuOpen: [12000, 277.14285714285757],
  XT004S_phoenix_sign: [14000, 4033.333333333331],
  XT005S_SC1_stop: [20000, 500],
  XT005S_SC4_stop: [22000, 1500],
  XT005S_SC_longspin_long: [25000, 6233.333333333334],
  XT005S_SC_longspin_middle: [33000, 4366.666666666667],
  XT005S_SC_longspin_short: [39000, 2500.0226757369646],
  XT005S_anticipation_high: [43000, 4299.999999999997],
  XT005S_anticipation_low: [49000, 1666.6666666666642],
  XT005S_bg_base_loop: [52000, 79285.71428571428, true],
  XT005S_bg_challenge1_loop: [133000, 10666.689342403628, true],
  XT005S_bg_challenge2_loop: [145000, 10666.689342403628, true],
  XT005S_bg_challenge3_loop: [157000, 10666.689342403628, true],
  XT005S_bg_challenge_roulette_loop: [169000, 11162.811791383207, true],
  XT005S_bg_gamble_loop: [182000, 10666.689342403628, true],
  XT005S_bg_gotcha_loop: [194000, 27187.551020408166, true],
  XT005S_bg_result_loop: [223000, 7500, true],
  XT005S_bg_scaling: [232000, 7500],
  XT005S_bigwin_loop: [241000, 7500, true],
  XT005S_challenge_roulette: [250000, 7000],
  XT005S_challenge_roulette_stop: [258000, 3000],
  XT005S_challenge_round: [262000, 2020.4308390022447],
  XT005S_choice: [266000, 500],
  XT005S_choice_select: [268000, 2000],
  XT005S_feature_trigger: [271000, 5000],
  XT005S_gamble_aori_fast: [277000, 10000],
  XT005S_gamble_aori_mid: [288000, 10000],
  XT005S_gamble_aori_slow: [299000, 8000],
  XT005S_gamble_indicator_fix: [308000, 2000.0226757369433],
  XT005S_gamble_indicator_green: [312000, 1006.2585034013409],
  XT005S_gamble_indicator_red: [315000, 542.0861678004485],
  XT005S_gamble_indicator_white: [317000, 333.3333333333144],
  XT005S_gamble_lose: [319000, 3466.66666666664],
  XT005S_gamble_trigger: [324000, 3000],
  XT005S_gotcha2: [328000, 2000],
  XT005S_gotcha3: [331000, 3000],
  XT005S_push_buy_feature: [335000, 366.66666666667425],
  XT005S_scaling_count_add1: [337000, 500],
  XT005S_scaling_count_add10: [339000, 500],
  XT005S_scaling_count_add2: [341000, 500],
  XT005S_scaling_count_add3: [343000, 500],
  XT005S_scaling_count_add4: [345000, 500],
  XT005S_scaling_count_add5: [347000, 500],
  XT005S_scaling_count_add6: [349000, 500],
  XT005S_scaling_count_add7: [351000, 500],
  XT005S_scaling_count_add8: [353000, 500],
  XT005S_scaling_count_add9: [355000, 500],
  XT005S_scaling_count_base: [357000, 9500],
  XT005S_scaling_count_end: [368000, 2500],
  XT005S_spin_loop: [372000, 2000.0226757369433, true],
  XT005S_spin_start: [376000, 1000],
  XT005S_spin_stop1: [378000, 500.4308390022629],
  XT005S_spin_stop2: [380000, 500.4308390022629],
  XT005S_spin_stop3: [382000, 500.4308390022629],
  XT005S_spin_stop4: [384000, 500.4308390022629],
  XT005S_spin_stop5: [386000, 500.4308390022629],
  XT005S_wheel_miss: [388000, 1500],
  XT005S_wheel_nice: [391000, 2000],
  XT005S_wheel_rotate: [394000, 4500],
  XT005S_wheel_spin_push: [400000, 2000],
  XT005S_win_big: [403000, 1800.0453514738979],
  XT005S_win_end: [406000, 1000],
  XT005S_win_epic: [408000, 3000.0453514739434],
  XT005S_win_great: [413000, 1800.0453514738979],
  XT005S_win_loop: [416000, 1000, true],
  XT005S_win_mega: [418000, 1800.0453514738979],
};
