import { Event, TrackEntry } from '@pixi-spine/all-4.1';
import { BitmapText, Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { FishKind, ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import {
  setBetAmount,
  setCurrency,
  setIsContinueAutoSpinsAfterFeature,
  setIsFishingUserActionWait,
  setIsStopOnAnyWin,
  setIsStopOnFeatureWin,
  setWinAmount,
} from '../../gql/cache';
import i18n from '../../i18next';
import { normalizeCoins, showCurrency, transitionToBaseGame } from '../../utils';
import { getFishNameText, getFishRankText } from '../../utils/i18n';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
//import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import AutoResizeText from '../components/autoResizeText';
import ButtonContainer from '../components/buttonContainer';
import ViewContainer from '../components/container';
import { layerFishingScaling } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

import {
  fishingScalingBetTitleTextStyles,
  fishingScalingFishNamePortraitTextStyles,
  fishingScalingMultiplierTextStyles,
  fishingScalingRankPortraitTextStyles,
  fishingScalingRankTitlePortraitTextStyles,
  fishingScalingWinValueLandscapeTextStyles,
  fishingScalingWinValuePortraitTextStyles,
} from './textStyle';

const rankToAnim: Record<FishKind, string> = {
  Rank1: 'pt01',
  Rank2: 'pt02',
  Rank3: 'pt03',
  Rank4: 'pt04',
  Rank5: 'pt05',
  Rank6: 'pt06',
  Rank7: 'pt07',
};

const COUNTUP_TO_TARGET1_DURATION = 2000;
const CountUpInfo: Record<FishKind, { start: number; add: number; target1: number; minDuration: number }> = {
  Rank1: {
    start: 2700,
    add: 35,
    target1: 2980,
    minDuration: 0.5,
  },
  Rank2: {
    start: 870,
    add: 35,
    target1: 1150,
    minDuration: 0.5,
  },
  Rank3: {
    start: 225,
    add: 25,
    target1: 425,
    minDuration: 0.5,
  },
  Rank4: {
    start: 30,
    add: 15,
    target1: 150,
    minDuration: 0.5,
  },
  Rank5: {
    start: 25,
    add: 5,
    target1: 65,
    minDuration: 0.5,
  },
  Rank6: {
    start: 21,
    add: 1,
    target1: 29,
    minDuration: 0.5,
  },
  Rank7: {
    start: 1,
    add: 1,
    target1: 9,
    minDuration: 0.5,
  },
};
class FishScaling extends ViewContainer {
  private base: TickerSpine<'sw'>;
  private effect: TickerSpine<'sw'>;

  private winMessage: TickerSpine<'message_win'>;

  private fishRank: FishKind;
  private layout: 'portrait' | 'landscape';

  private multiplierText: BitmapText;
  private rankTitleText: AutoResizeText;
  private rankText: AutoResizeText;

  private rankTextContainer: Container;
  private fishText: AutoResizeText;

  private winTitle: TickerSpine<'sw'>;
  private betTitle: AutoResizeText;

  private winValueText: AutoResizeText;

  //private winValueMultiplierText: AutoResizeText;

  private multiplierSceneContainer: Container;
  private winSceneContainer: Container;

  private countUpAnimation: Animation;

  private targetMultiplier: number;

  private shutter: TickerSpine<'sw'>;

  private syutyu: TickerSpine<'sw'>;

  private state: 'None' | 'multiplier' | 'win';

  private dispMultiplierValue: number;
  private winValue: number;

  private soundCount: number;

  private winDelayAnimation: Animation;

  constructor() {
    super();

    this.state = 'None';
    this.layout = 'landscape';
    this.fishRank = 'Rank7';
    this.targetMultiplier = 1;
    this.dispMultiplierValue = 0;
    this.soundCount = 0;
    this.winValue = 0;
    //this.skipDisable = true;

    this.base = new TickerSpine('sw');
    this.base.state.setAnimation(0, 'y_cu_pt01', true);

    this.effect = new TickerSpine('sw');
    this.effect.state.setAnimation(0, 'y_eff', true);

    this.rankTitleText = new AutoResizeText(
      i18n.t('fishing.scaling.rankTitle'),
      fishingScalingRankTitlePortraitTextStyles,
    );
    this.rankTitleText.anchor.set(0, 0.5);

    this.rankText = new AutoResizeText(getFishRankText('Rank7'), fishingScalingRankPortraitTextStyles);
    this.rankText.anchor.set(0, 0.5);

    this.rankTextContainer = new Container();
    this.rankTextContainer.addChild(this.rankTitleText, this.rankText);

    this.fishText = new AutoResizeText(getFishNameText('Rank7'), fishingScalingFishNamePortraitTextStyles);
    this.fishText.anchor.set(0.5);

    this.winTitle = new TickerSpine('sw');
    this.winTitle.state.setAnimation(0, 'title');

    this.betTitle = new AutoResizeText(i18n.t('fishing.scaling.betAmount'), fishingScalingBetTitleTextStyles);
    this.betTitle.anchor.set(0.5);

    this.multiplierText = new BitmapText('x0000', fishingScalingMultiplierTextStyles);
    this.multiplierText.anchor.set(0.5);

    this.syutyu = new TickerSpine('sw');
    this.syutyu.visible = false;

    this.multiplierSceneContainer = new Container();
    this.multiplierSceneContainer.addChild(
      this.syutyu,
      this.effect,
      this.rankTextContainer,
      //this.rankTitleText,
      //this.rankText,
      this.fishText,
      this.winTitle,
      this.betTitle,
      this.multiplierText,
    );

    //win message
    this.winMessage = new TickerSpine('message_win');
    this.winMessage.state.setAnimation(0, 'base', true);
    this.winMessage.skeleton.setSkinByName('bigwin');

    this.winValueText = new AutoResizeText('', fishingScalingWinValuePortraitTextStyles);
    this.winValueText.anchor.set(0.5);

    /*
    this.winValueMultiplierText = new AutoResizeText('', fishingScalingWinMultiplierPortraitTextStyles);
    this.winValueMultiplierText.anchor.set(0.5);
*/
    this.winSceneContainer = new ButtonContainer(-2000, -2000, 4000, 4000, () => {
      if (!setIsFishingUserActionWait()) return;

      setIsFishingUserActionWait(false);

      BgmControl.fadeOutAll(1000);

      transitionToBaseGame(() => {
        this.visible = false;
      });
    });
    //this.winSceneContainer.addChild(this.winMessage, this.winValueText, this.winValueMultiplierText);
    this.winSceneContainer.addChild(this.winMessage, this.winValueText);
    this.winSceneContainer.visible = false;

    this.countUpAnimation = new AnimationGroup();
    this.winDelayAnimation = new AnimationGroup();

    this.shutter = new TickerSpine('sw');
    this.shutter.visible = false;

    this.addChild(this.base, this.multiplierSceneContainer, this.winSceneContainer, this.shutter);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.addListener(EventTypes.FISHING_SCALING_START, this.start.bind(this));
    eventManager.addListener(EventTypes.SCALING_SKIP, this.skip.bind(this));
    eventManager.addListener(EventTypes.SCALING_BANNER_SKIP, () => {
      if (!setIsFishingUserActionWait()) return;

      if (this.state === 'multiplier') {
        this.skip();
      } else if (this.state === 'win') {
        this.winDelayAnimation.skip();
        setIsFishingUserActionWait(false);

        BgmControl.fadeOutAll(1000);
        transitionToBaseGame(() => {
          this.visible = false;
        });
      }
    });
    this.parentLayer = layerFishingScaling;

    this.initSpineListener();

    this.visible = false;

    //test
    //this.visible = true;
    //this.start('Rank4', 500);
  }

  private initSpineListener() {
    this.shutter.state.addListener({
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === 'message_win') {
          this.startWinAnime();
          this.showWinValue();

          if (setIsContinueAutoSpinsAfterFeature() && !setIsStopOnAnyWin() && !setIsStopOnFeatureWin()) {
            const autoSkipDelay = Tween.createDelayAnimation(4000);
            autoSkipDelay.addOnComplete(() => {
              setIsFishingUserActionWait(false);

              transitionToBaseGame(() => {
                this.visible = false;
              });
            });
            autoSkipDelay.start();
          }
        }
      },
    });
  }

  private startCountAnime() {
    const ptn = rankToAnim[this.fishRank];
    const layout = this.layout === 'portrait' ? 't' : 'y';
    const base = `${layout}_cu_${ptn}` as SpineInterface['sw']['animations'];
    const effect = `${layout}_eff` as SpineInterface['sw']['animations'];

    this.base.state.setAnimation(0, base, true);
    this.effect.state.setAnimation(0, effect, true);
    this.syutyu.state.setAnimation(0, 'syutyu', true);

    this.syutyu.visible = true;
    this.effect.visible = true;

    this.state = 'multiplier';
    //AudioApi.play({ type: ISongs.XT005S_win_loop, stopPrev: true });

    if (this.layout === 'portrait') {
      eventManager.emit(EventTypes.FISHING_SCALING_SHUTTER_BTN_SET_VISIBLE, true);
    }
  }

  private startWinAnime() {
    const ptn = rankToAnim[this.fishRank];
    const layout = this.layout === 'portrait' ? 't' : 'y';
    const base = `${layout}_message_${ptn}` as SpineInterface['sw']['animations'];
    const effect = `${layout}_eff` as SpineInterface['sw']['animations'];

    this.base.state.setAnimation(0, base, false);
    this.effect.state.setAnimation(0, effect, true);

    this.effect.visible = false;
    this.syutyu.visible = false;

    this.state = 'win';

    eventManager.emit(EventTypes.FISHING_SCALING_SHUTTER_BTN_SET_VISIBLE, false);
  }

  private updateAnim() {
    if (this.state === 'win') {
      this.startWinAnime();
    } else if (this.state === 'multiplier') {
      this.startCountAnime();
    }
  }

  public start(fishRank: FishKind, multiplier: number) {
    setIsFishingUserActionWait(false);

    this.soundCount = 0;
    this.dispMultiplierValue = 0;
    this.targetMultiplier = multiplier;
    this.multiplierSceneContainer.visible = true;
    this.winSceneContainer.visible = false;
    this.fishRank = fishRank;
    this.winValueText.text = `${formatNumber({
      currency: setCurrency(),
      value: setBetAmount() * multiplier,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winValue = setBetAmount() * multiplier;
    this.visible = true;

    //text update
    this.fishText.text = getFishNameText(fishRank);
    this.fishText.updateText(true);
    this.rankText.text = getFishRankText(fishRank);
    this.rankText.updateText(true);

    requestAnimationFrame(() => {
      this.updateRankTextPosition();
    });

    if (100 <= multiplier) {
      this.winMessage.skeleton.setSkinByName('epicwin');
    } else if (50 <= multiplier) {
      this.winMessage.skeleton.setSkinByName('greatwin');
    } else if (25 <= multiplier) {
      this.winMessage.skeleton.setSkinByName('megawin');
    } else {
      this.winMessage.skeleton.setSkinByName('bigwin');
    }

    this.startCountAnime();

    const winAnimationDelay = Tween.createDelayAnimation(2000);
    winAnimationDelay.addOnComplete(() => {
      this.startShutter();
    });

    this.multiplierText.scale.set(1);
    this.countUpAnimation = this.createCountAnimation(multiplier);
    AudioApi.play({ type: ISongs.XT005S_scaling_count_base, stopPrev: true });

    this.countUpAnimation.addOnComplete(() => {
      //AudioApi.stop({ type: ISongs.XT005S_win_loop, stopPrev: true });
      winAnimationDelay.start();
    });
    this.countUpAnimation.start();

    BgmControl.stopAll();
    AudioApi.play({ type: ISongs.XT005S_bg_scaling, loop: false });

    const actionWaitDelay = Tween.createDelayAnimation(2000);
    actionWaitDelay.addOnComplete(() => {
      setIsFishingUserActionWait(true);
    });
    actionWaitDelay.start();

    this.countUpAnimation.addOnSkip(() => {
      actionWaitDelay.skip();
      winAnimationDelay.skip();
    });
  }

  private startShutter() {
    this.shutter.state.setAnimation(0, 'y_shutter', false);
    this.shutter.visible = true;
    AudioApi.stop({ type: ISongs.XT005S_scaling_count_base });
    AudioApi.play({ type: ISongs.XT005S_scaling_count_end, stopPrev: true });
    setIsFishingUserActionWait(false);
  }

  private skip() {
    if (!setIsFishingUserActionWait()) return;

    this.countUpAnimation.skip();
    this.startShutter();
    //this.showWinValue();
  }

  private showWinValue() {
    eventManager.emit(EventTypes.SCALING_STOP);

    //this.winValueMultiplierText.text = `x${Math.floor(this.targetMultiplier)}`;

    this.multiplierSceneContainer.visible = false;
    this.winSceneContainer.visible = true;
    AudioApi.stop({ type: ISongs.XT005S_bg_scaling });

    this.winDelayAnimation = Tween.createDelayAnimation(1500);
    this.winDelayAnimation.addOnComplete(() => {
      setIsFishingUserActionWait(true);
      BgmControl.playBgm('result', true, true);

      if (100 <= this.targetMultiplier) {
        AudioApi.play({ type: ISongs.XT005S_win_epic, loop: false });
      } else if (50 <= this.targetMultiplier) {
        AudioApi.play({ type: ISongs.XT005S_win_great, loop: false });
      } else if (25 <= this.targetMultiplier) {
        AudioApi.play({ type: ISongs.XT005S_win_mega, loop: false });
      } else {
        AudioApi.play({ type: ISongs.XT005S_win_big, loop: false });
      }

      setWinAmount(this.winValue);
      //eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);

      //setCurrentFreeSpinsTotalWin(this.winValue);
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(this.winValue),
          showCurrency: showCurrency(setCurrency()),
        }),
      );
    });
    this.winDelayAnimation.start();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (settings.mode === GameMode.SCALING) {
      this.visible = false;
      this.state = 'None';
      setIsFishingUserActionWait(false);
    } else {
      eventManager.emit(EventTypes.FISHING_SCALING_SHUTTER_BTN_SET_VISIBLE, false);
      this.visible = false;
      this.state = 'None';
    }
  }

  private createCountAnimation(target: number): Animation {
    const animationChain = new AnimationChain();

    const countInfo = CountUpInfo[this.fishRank];
    const value: { winValue: number } = { winValue: 0 };
    const countFirstNum = (countInfo.target1 - countInfo.start) / countInfo.add;

    const firstAnim = new AnimationGroup();

    const countUpFirstAnimation = new Tween({
      propertyBeginValue: 0,
      target: countFirstNum,
      object: value,
      property: TweenProperties.WIN_VALUE,
      update: this.setMultiplierFirstValue.bind(this),
      duration: COUNTUP_TO_TARGET1_DURATION,
    });

    firstAnim.addAnimation(countUpFirstAnimation);

    animationChain.appendAnimation(firstAnim);

    const baseWinAnimation = new AnimationGroup({});

    const targetMultiplier = target;
    const duration =
      targetMultiplier > countInfo.target1 ? ((targetMultiplier - countInfo.target1) * 500) / countInfo.add : 0;
    //baseWinAnimation.duration = duration;
    const countTargetNum = (targetMultiplier - countInfo.target1) / countInfo.add;

    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: countTargetNum,
      object: value,
      property: TweenProperties.WIN_VALUE,
      update: this.setMultiplierValue.bind(this),
      duration,
    });

    /*const scaleXAnimation = new Tween({
      object: this.multiplierText.scale,
      propertyBeginValue: 1,
      target: 1.2,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.multiplierText.scale,
      propertyBeginValue: 1,
      target: 1.2,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    */
    baseWinAnimation.addAnimation(countUpAnimation);

    animationChain.appendAnimation(baseWinAnimation);
    return animationChain;
  }

  private setMultiplierFirstValue(value: number) {
    const multiplier = CountUpInfo[this.fishRank].start + Math.floor(value) * CountUpInfo[this.fishRank].add;
    this.multiplierText.text = `x${multiplier}`;

    if (this.dispMultiplierValue != multiplier) {
      this.dispMultiplierValue = multiplier;
      //AudioApi.play({ type: ISongs.XT005S_gamble_indicator_green, stopPrev: true });
    }
  }
  private setMultiplierValue(value: number) {
    const multiplier = CountUpInfo[this.fishRank].target1 + Math.floor(value) * CountUpInfo[this.fishRank].add;
    this.multiplierText.text = `x${multiplier}`;

    if (this.dispMultiplierValue != multiplier) {
      this.dispMultiplierValue = multiplier;

      const soundName = `XT005S_scaling_count_add${this.soundCount + 1}`;
      AudioApi.play({ type: soundName, stopPrev: true });
      //this.multiplierText.scale.set(this.multiplierText.scale.x + 0.05);
      this.soundCount = this.soundCount < 9 ? this.soundCount + 1 : 9;
    }
  }

  private updateRankTextPosition() {
    const space = 11;
    const width = this.rankTitleText.width + this.rankText.width + space;

    this.rankTitleText.x = -width / 2;
    this.rankText.x = this.rankTitleText.x + this.rankTitleText.width + space;
  }
  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? gameLayout.scaling.landscape : gameLayout.scaling.portrait;

    this.base.position.copyFrom(layout.base);
    this.syutyu.position.copyFrom(layout.base);
    this.effect.position.copyFrom(layout.base);
    this.shutter.position.copyFrom(layout.base);

    //this.rankTitleText.position.copyFrom(layout.rankTitle);
    //this.rankText.position.copyFrom(layout.rank);
    this.rankTextContainer.position.copyFrom(layout.rankTextContainer);
    this.fishText.position.copyFrom(layout.name);
    this.winTitle.position.copyFrom(layout.winTitle);
    this.betTitle.position.copyFrom(layout.betTitle);

    this.multiplierText.position.copyFrom(layout.multiplier);

    this.winMessage.position.copyFrom(layout.winMessage);
    this.winMessage.scale.set(layout.winMessage.scale);

    this.winValueText.position.copyFrom(layout.winValue);
    this.winValueText.updateTextStyle(
      width > height ? fishingScalingWinValueLandscapeTextStyles : fishingScalingWinValuePortraitTextStyles,
    );

    if (this.state === 'multiplier') {
      eventManager.emit(EventTypes.FISHING_SCALING_SHUTTER_BTN_SET_VISIBLE, true);
    } else {
      eventManager.emit(EventTypes.FISHING_SCALING_SHUTTER_BTN_SET_VISIBLE, false);
    }

    if (width > height) {
      if (this.layout === 'portrait') {
        this.layout = 'landscape';
        this.updateAnim();
      }
    } else {
      if (this.layout === 'landscape') {
        this.layout = 'portrait';
        this.updateAnim();
      }
    }
  };
}

export default FishScaling;
