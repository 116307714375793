import type TaggedText from 'pixi-tagged-text';
import * as PIXI from 'pixi.js';

import { Colors, Variables } from '../../../config';
import { AutoResizeTextStyle } from '../../components/autoResizeText';

type TextStyleSet = (typeof TaggedText)['defaultStyles'];

export const introAutoResizeTextStylesInfo: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  lineJoin: 'round',
  align: 'center',
  dropShadow: false,
  fontStyle: 'normal',
  fill: '#101013',
  stroke: 0xffffff,
  strokeThickness: 5,
  fontSize: 39,
  fontWeight: '700',
  letterSpacing: 0,
  lineHeight: 40,
  maxWidth: 453,
  maxHeight: 249,
};

export const introOuterAutoResizeTextStylesInfo: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  lineJoin: 'round',
  align: 'left',
  dropShadow: false,
  fontStyle: 'normal',
  fill: '#fff',
  stroke: 0xffffff,
  fontSize: 36,
  fontWeight: '700',
  letterSpacing: 0,
  lineHeight: 42,
  maxWidth: 1024,
  maxHeight: 75,
};

export const introTitleStylesInfo: TextStyleSet = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    lineJoin: 'round',
    align: 'center',
    fontStyle: 'normal',
    wordWrapWidth: 1200,
    wordWrapHeight: 158,
    fill: ['#FF0000', '#FF8800'],
    fillGradientStops: [0, 1],
    fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
    stroke: 0xffffff,
    strokeThickness: 6,
    fontSize: 96,
    fontWeight: '700',
    letterSpacing: 0,
    lineHeight: 96,
    dropShadow: true,
    dropShadowAngle: Math.atan2(10, 0),
    dropShadowBlur: 0,
    dropShadowDistance: Math.sqrt(10 * 10),
    dropShadowColor: '#000',
    dropShadowAlpha: 0.8,
  },
  span: {
    fill: Colors.GAME_COLOR,
  },
};
export const introDescriptionStylesInfo: TextStyleSet = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    lineJoin: 'round',
    align: 'center',
    fontStyle: 'normal',
    wordWrapWidth: 1200,
    wordWrapHeight: 180,
    fill: '#ffffff',
    fontSize: 50,
    fontWeight: '700',
    letterSpacing: 0,
    lineHeight: 50,
    dropShadow: true,
    dropShadowAngle: Math.atan2(8, 0),
    dropShadowBlur: 0,
    dropShadowDistance: Math.sqrt(8 * 8),
    dropShadowColor: '#000',
    dropShadowAlpha: 0.8,
  },
  span: {
    fill: Colors.GAME_COLOR,
  },
};
