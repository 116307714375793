import { ReelStopSoundType } from '../../global';
import { fishingChallengeRoundPortraitTextStyles, fishingChallengeRoundTextStyles } from '../fishing/textStyle';

export * from './textStyles';
export * from './events';
export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
}

export type GameViewState = 'Base';

// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
export const WIN_ANIMATION_INTERVAL = 1_000;
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREE_SPIN = 1_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.0;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 499 / 16097;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.1;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 499 / 16097;
export const BIG_WIN_END_DURATION = 1000;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.2;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 499 / 4561;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.3;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 499 / 2153;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.4;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 499 / 983;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const WIN_SLOT_TINT_COLOR = 0x888888;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 4;
export const SLOTS_PER_REEL_AMOUNT = 1;
export const REEL_WIDTH = 390;
export const SLOT_RESOURCE_WIDTH = 390;
export const SLOT_RESOURCE_HEIGHT = 390;
export const SLOT_WIDTH = 390;
export const SLOT_HEIGHT = 390;
export const SLOT_SCALE = 1.0;
export const SHOW_ALL_LINES_ON_WIN = true;
export const SLOT_REELMASK_X = 0;
export const SLOT_REELMASK_Y = 0;

export const RESPIN_REEL_INDEX = 2;

export const SLOT_REELMASK_WIDTH = 1203;
export const SLOT_REELMASK_HEIGHT = 696;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * 2;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * 2;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 50;
export const GAME_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;
export const SLOTS_BACKGROUND_WIDTH = 1300;
export const SLOTS_BACKGROUND_HEIGHT = 800;

//reelFrame
export const SLOTS_REELFRAME_WIDTH = 946;
export const SLOTS_REELFRAME_HEIGHT = 896;
export const SLOTS_REELFRAME_X = -83;
export const SLOTS_REELFRAME_Y = -58;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 0;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;
export const SAFE_AREA_LANDSCAPE_WIDTH = 1920; //1600; 1324 + 328 + 328
export const SAFE_AREA_LANDSCAPE_HEIGHT = 1080; //601 + 300 + 300
export const SAFE_AREA_LANDSCAPE_PIVOT_X = -610;
export const SAFE_AREA_LANDSCAPE_PIVOT_Y = -47;
export const SAFE_AREA_PORTRAIT_WIDTH = 1213;
export const SAFE_AREA_PORTRAIT_HEIGHT = 2156; //601 + 435 * 2
export const SAFE_AREA_PORTRAIT_PIVOT_X = -205;
export const SAFE_AREA_PORTRAIT_PIVOT_Y = -507;

//export const FLEXIBLE_GAMEVIEW_POSY_RATIO = 0.5;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
//export const BASE_REEL_BLUR_VALUE = 18;
//export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// SPIN ANIMATION SETTINGS
export const BASE_REEL_STARTING_DURATION = 467;
export const BASE_REEL_FIRST_ROLLING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 600;
export const BASE_REEL_ROLLING_SPEED = 0.018;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 35_000;
export const TURBO_REEL_STARTING_DURATION = BASE_REEL_STARTING_DURATION;
export const TURBO_REEL_FIRST_ROLLING_DURATION = 125;
export const TURBO_REEL_ROLLING_DURATION = 100;
export const TURBO_REEL_ROLLING_SPEED = 0.05;
export const TURBO_REEL_ENDING_DURATION = 125;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_STARTING_DURATION) / 2.4;
export const REEL_STARTING_SLOTS_AMOUNT_DUMMY = (400 / SLOT_HEIGHT) * 3;
export const REEL_ENDING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_ENDING_DURATION) / 2; // (speed*duration) / (df/dt)(0)
export const BASE_REEL_STARTING_FORMULA = (t: number): number => t * t * (1.5 * t - 0.5);
//export const BASE_REEL_STARTING_FORMULA = (t: number): number => t * t * (3.5 * t - .5);
export const BASE_REEL_ENDING_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);

export const ANTICIPATION_BONUS_ENDING_DURATION: number[] = [2000, 4000, 6000];
export const ANTICIPATION_BONUS_ENDING_SLOTS_AMOUNT: number[] = [4, 8, 12];
export const ANTICIPATION_REEL_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);
export const ANTICIPATION_SLOTS_TINT = 0x7f7f7f;
export const ANTICIPATION_SHORT_START_REELID = 3;

export const REEL_BUYFEATURE_ROLLING_DURATION = 1000;
export const REEL_BUYFEATURE_MYSTERY_ENDING_DURATION = [1000, 1000, 1000];
export const REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT = [2, 2, 2];

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = REEL_WIDTH;
export const TINT_CONTAINER_HEIGHT = 820;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.3;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH * 1.5;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.75;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// FREE SPINS
export const FREE_SPINS_FADE_OUT_DURATION = 1000;
export const FREE_SPINS_FADE_IN_DURATION = 1000;
export const FREE_SPINS_TIME_OUT_BANNER = 3500;

export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

//export const BASEGAME_TO_FREESPIN_REEL_POSITIONS = [0, 0, 0, 0, 0];
//export const FREEGAME_TO_BASEGAME_REEL_POSITIONS = [3, 0, 5, 0, 0];

export const FREE_SPINS_ROUND_INTERVAL = 300;
export const FREE_SPINS_ROUND_INTERVAL_TURBO = 150;
// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}
// WIN TEXT SETTINGS
export const WINLABEL_POS_X = 400;
export const WINLABEL_POS_Y = SLOTS_CONTAINER_HEIGHT / 2 - 100;
export const WINCOUNTUP_TEXT_POS_X = 400;
export const WINCOUNTUP_LANDSCAPE_TEXT_POS_Y = 500;
export const WINCOUNTUP_PORTRAIT_TEXT_POS_Y = 500;
export const WINCOUNTUP_TURBO_MULTIPLIER = 3;

//JINGLE to WIN SETTING
export const WIN_ANIM_START_DELAY = 0;
export const JINGLE_TO_WIN_DURATION = 2000;

//RESPIN
export const FREESPIN_ENDING_DELAY_DURATION = 500;

//Specific
export const SOUND_REEL_STOP_SOUND_PRIORITY: Record<ReelStopSoundType, number> = {
  normal: 0,
  bonus: 2,
};

export const FREE_SPIN_START_REEL_POSITIONS = [36, 27, 25, 29, 39];

export const GAME_TITLE_POSITION_X = 676;
export const GAME_TITLE_POSITION_Y = -37;
export const GAME_TITLE_PORTRAIT_POSITION_X = 270;
export const GAME_TITLE_PORTRAIT_POSITION_Y = -197;

export const ROD_POSITION_X = -181;
export const ROD_POSITION_Y = 835;
export const ROD_PORTRAIT_POSITION_X = 303;
export const ROD_PORTRAIT_POSITION_Y = 599;

export const MESSAGE_WINDOW_POSITION_X = 409;
export const MESSAGE_WINDOW_POSITION_Y = 871;
export const MESSAGE_WINDOW_PORTRAIT_POSITION_X = 412;
export const MESSAGE_WINDOW_PORTRAIT_POSITION_Y = 885;

export const gameLayout = {
  gameTitle: {
    landscape: {
      x: 8 + 547 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
      y: 270 + 363 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      scale: 547 / 636,
    },
    portrait: {
      x: 291 + 636 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
      y: 26 + 422 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      scale: 1,
    },
  },
  rod: {
    landscape: {
      x: -572 + 1818 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
      y: -332 + 3143 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      scale: 1818 / 1254,
    },
    portrait: {
      x: -170 + 1254 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
      y: 46 + 2168 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      scale: 1,
    },
  },
  messageWindow: {
    landscape: {
      x: 440 + 1138 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
      y: 847 + 122 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      scale: 1,
    },
    portrait: {
      x: 38 + 1138 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
      y: 1321 + 122 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      scale: 1,
    },
  },
  buyFeatureBtn: {
    landscape: {
      x: 165 + 228 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
      y: 814 + 126 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      scale: 1.0,
    },
  },
  buyfeaturePopup: {
    portrait: { x: 15 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 177 + SAFE_AREA_PORTRAIT_PIVOT_Y, scale: 1 },
    landscape: { x: 560 + SAFE_AREA_LANDSCAPE_PIVOT_X, y: 6 + SAFE_AREA_LANDSCAPE_PIVOT_Y, scale: 876 / 1192 },
  },
  fishingRoulette: {
    landscape: {
      title: {
        x: 622 + 764 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 11 + 82 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
        scale: 764 / 1092,
      },
      icon: {
        x: 490 + 1034 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 48 + 902 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
        scale: 1034 / 1210,
      },
    },
    portrait: {
      title: {
        x: 63 + 1092 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 209 + 118 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
        scale: 1,
      },
      icon: {
        x: 0 + 1210 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 348 + 1056 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
        scale: 1210 / 1210,
      },
    },
  },
  fishingChallenge: {
    Landscape: {
      wheelContainer: {
        x: 484 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 88 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
        scale: 880 / 1023,
      },
      roundText: {
        x: 718 + 613 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 314 + 157 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
        style: fishingChallengeRoundTextStyles,
      },
      ButtonContainer: {
        x: 423,
        y: 503,
      },
    },
    PortRait: {
      wheelContainer: { x: 5 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 402 + SAFE_AREA_PORTRAIT_PIVOT_Y, scale: 1 },
      roundText: {
        x: 312 + 639 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 631 + 183 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
        style: fishingChallengeRoundPortraitTextStyles,
      },
      ButtonContainer: {
        x: 423,
        y: 413,
      },
    },
  },
  fishMeter: {
    landscape: {
      Container: {
        x: 775 + 478 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 7 + 98 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
        scale: 478 / 870,
      },
      textBaseContainer: {
        x: 658 + 754 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 121 + 48 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
    },
    Portrait: {
      Container: {
        x: 182 + 870 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 89 + 178 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
        scale: 1,
      },
      textBaseContainer: {
        x: 184 + 870 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 290 + 88 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
    },
  },
  gambleSelect: {
    landscape: {
      container: {
        x: 513 + 958 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 70 + 820 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
        scale: 958 / 1198,
      },
      title: { x: 428.5, y: -22 },
    },
    portrait: {
      container: {
        x: 6 + 1198 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 279 + 1026 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
        scale: 1,
      },
      title: { x: 102 + 986 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 155 + 123 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y },
    },
  },
  scaling: {
    landscape: {
      base: {
        x: 1920 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 1080 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      rankTextContainer: {
        x: 605 + 619 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: -4 + 123 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      /*
      rankTitle: {
        x: 634 + 224 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 0 + 108 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      rank: {
        x: 879 + 311 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: -7 + 123 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      */
      name: {
        x: 608 + 616 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 89 + 108 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      winTitle: {
        x: 533 + 802 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 214 + 124 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      betTitle: {
        x: 533 + 800 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 345 + 108 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      multiplier: {
        x: 358 + 1041 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 466 + 294 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      winMessage: {
        x: 523 + 790 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 118 + 490 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
        scale: 790 / 1000,
      },
      winValue: {
        x: 258 + 1361 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 618 + 147 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
      shutterBtn: {
        x: 459 + 292 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X,
        y: 1654 + 292 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y,
      },
    },
    portrait: {
      base: {
        x: 1213 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 2156 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      rankTextContainer: {
        x: 300 + 619 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 1 + 123 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      /*
      rankTitle: {
        x: 329 + 227 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 8 + 109 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      rank: {
        x: 579 + 308 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 2 + 121 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      */
      name: {
        x: 315 + 597 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 99 + 109 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      winTitle: {
        x: 194 + 802 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 678 + 124 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      betTitle: {
        x: 200 + 800 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 805 + 108 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      multiplier: {
        x: 86 + 1041 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 931 + 294 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      winMessage: {
        x: 106 + 1000 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 107 + 620 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
        scale: 1,
      },
      winValue: {
        x: 151 + 931 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 667 + 247 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
      shutterBtn: {
        x: 459 + 292 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X,
        y: 1654 + 292 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y,
      },
    },
  },
};
