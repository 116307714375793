/*
const test = {
  a: {
    key: 'title',
    type: 'AutoResizeText',
    position: { x: 0, y: 0 },
    anchor: { x: 0, y: 0 },
    i18n:
    style:
  },
};
*/
export const FEATURE_BTN_DEFAULT_SCALE = 1;
export const FEATURE_BTN_MOUSE_OVER_SCALE = 1 * FEATURE_BTN_DEFAULT_SCALE;
export const FEATURE_BTN_MOUSE_CLICK_SCALE = 1 * FEATURE_BTN_DEFAULT_SCALE;

export const FEATURE_BTN_SPINE_WIDTH = 218;
export const FEATURE_BTN_SPINE_HEIGHT = 114;
export const FEATURE_BTN_LANDSCAPE_X = -435;
export const FEATURE_BTN_LANDSCAPE_Y = 777;

export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 0;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 0;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 4;

export const FEATURE_POPUP_SPINE_WIDTH = 1208;

export const FEATURE_POPUP_POSITIONS = {
  BaseContainer: {
    Portrait: { x: 0, y: 0 },
    Landscape: { x: 0, y: 0 },
    Base: { x: 596, y: 605 },
    Title: { x: 591, y: 76 },
    CancelBtn: { x: 369, y: 976 },
    OkBtn: { x: 799, y: 975 },
  },
  MenuContainer: {
    position: { x: 84, y: 210 },
    Normal: {
      size: { w: 498, h: 412 },
      Container: { x: 0, y: 0 },
      Base: { x: 240, y: 195 },
      Title: { x: 240, y: 55 },
      Cost: { x: 240, y: 300 },
    },
    Special: {
      size: { w: 498, h: 412 },
      Container: { x: 527, y: 0 },
      Base: { x: 240, y: 195 },
      Title: { x: 240, y: 55 },
      Cost: { x: 240, y: 300 },
      Description: { x: 238, y: 334 },
    },
    BetMenu: {
      Container: { x: 135, y: 445 },
      BetTitle: { x: 365, y: 32 },
      MinusBtn: { x: 48, y: 144 },
      PlusBtn: { x: 690, y: 144 },
      BetBase: { x: 371, y: 152 },
      BetValue: { x: 371, y: 152 },
    },
  },
  Confirm: {
    description: { x: 598, y: 185 },
    iconContainer: { x: 343, y: 344 },
    totalCostText: { x: 592, y: 737 },
    totalCostValue: { x: 596, y: 827 },
  },
};

export const FEATURE_POPUP_MP_BTN_HITAREA_SCALE = 1.8;
