//import * as PIXI from 'pixi.js';

//export let scalingMultiBitmapFont: PIXI.BitmapFont | undefined = undefined;
export async function createBitmapFont() {
  /*
  const chars = 'x0123456789';
  const charset = new Set(chars);
  //  charset.add(' ');

  countUpMultiTextStyle.layerList.forEach((style, index) => {
    BitmapFont.from(`${countUpMultiTextStyle.name}.${index + 1}`, style.style, {
      chars: [...charset],
      textureWidth: 1024,
    });
  });

  // フォントの利用確認
  const scalingMultiBitmapFont = PIXI.BitmapFont.available['Arial'];
  if (!scalingMultiBitmapFont) {
    throw new Error('Failed to load countUpTextStyle.fnt');
  }
  */
}
