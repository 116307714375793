import { Bone, Event, TrackEntry } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { FishKind, ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { setFishingResult, setIsSpinShortCut, setIsWheelSpinning, setNextResult } from '../../gql/cache';
import i18n from '../../i18next';
import { getBetData, isFishingChallengeMode, transitionToBaseGame } from '../../utils';
import Tween from '../animations/tween';
import { LotsFishingPhoenix } from '../announce/config';
import { getRandomFromUUID, getResultFromTbl } from '../announce/utils';
import { BgmControl } from '../bgmControl/bgmControl';
import AutoResizeText from '../components/autoResizeText';
import ButtonContainer from '../components/buttonContainer';
import ViewContainer from '../components/container';
import { layerBannerBlackBoard, layerFishingChallenge } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

import { FISHING_CHALLENGE_WHEEL_SKIP_SPEED, FishingLayout } from './config';
import FishMeter from './fishMeter';
import { fishingChallengeRoundTextStyles } from './textStyle';

class FishingChallenge extends ViewContainer {
  private fishRank: FishKind;
  private sign: TickerSpine<'fc_wheel'>;
  private wheel: TickerSpine<'fc_wheel'>;
  private flash: TickerSpine<'fc_wheel'>;
  private frame: TickerSpine<'fc_wheel'>;
  private frameEffect: TickerSpine<'fc_wheel'>;
  private nice: TickerSpine<'fc_wheel'>;
  private roundSpine: TickerSpine<'fc_wheel'>;

  private roundText: AutoResizeText;

  private wheelBone: Bone;

  private frameBone: Bone;

  private meter: FishMeter;

  private targetPos: number;

  private wheelSpinCnt: number;

  private buttonContainer: ButtonContainer;

  private spinButton: TickerSpine<'fc_wheel'>;

  private rouletteContainer: PIXI.Container;

  private rotationNum: number;

  private effectRotation: number;

  private spinAnimationName: 'pnl_rot' | 'pnl_rot_over';
  private spinState: 'wait' | 'spinning';

  constructor() {
    super();

    this.fishRank = 'Rank1';
    this.targetPos = 0;
    this.wheelSpinCnt = 0;
    this.rotationNum = 0;
    this.effectRotation = 0;
    this.spinState = 'wait';
    this.spinAnimationName = 'pnl_rot';

    this.sign = new TickerSpine('fc_wheel');
    this.sign.position.copyFrom(FishingLayout.Challenge.Sign.Base);
    this.sign.state.setAnimation(0, 'sgin_wait', true);

    this.wheel = new TickerSpine('fc_wheel');
    this.wheel.position.copyFrom(FishingLayout.Challenge.Wheel.Base);
    this.wheel.state.setAnimation(0, 'pnl_wait', true);

    this.flash = new TickerSpine('fc_wheel');
    this.flash.position.copyFrom(FishingLayout.Challenge.Wheel.Base);

    this.frame = new TickerSpine('fc_wheel');
    this.frame.position.copyFrom(FishingLayout.Challenge.Wheel.Base);
    this.frame.state.setAnimation(0, 'frame_wait', true);

    this.frameEffect = new TickerSpine('fc_wheel');
    this.frameEffect.position.copyFrom(FishingLayout.Challenge.Wheel.Base);
    this.frameEffect.state.setAnimation(0, 'frame_rot', false);
    this.frameEffect.visible = false;

    this.nice = new TickerSpine('fc_wheel');
    this.nice.position.copyFrom(FishingLayout.Challenge.Wheel.ResultText);
    this.nice.state.setAnimation(0, 'nice_loop', true);
    this.nice.visible = false;

    this.roundSpine = new TickerSpine('fc_wheel');
    this.roundSpine.position.copyFrom(FishingLayout.Challenge.Wheel.ResultText);
    this.roundSpine.skeleton.setSkinByName('default');
    this.roundSpine.state.setAnimation(0, 'systemfont', false);
    this.roundSpine.visible = false;

    this.roundText = new AutoResizeText(
      i18n.t('fishing.challenge.round', { cnt: 1, max: 3 }),
      fishingChallengeRoundTextStyles,
    );
    this.roundText.position.copyFrom(FishingLayout.Challenge.Round.landscape.pos);
    this.roundText.anchor.set(0.5);
    this.roundText.alpha = 0;

    this.meter = new FishMeter();

    this.spinButton = new TickerSpine('fc_wheel');
    this.spinButton.state.setAnimation(0, 'btn_def_eff', true);
    this.spinButton.position.copyFrom(FishingLayout.Challenge.Button);

    this.buttonContainer = new ButtonContainer(
      -130,
      -130,
      250,
      250,
      this.onSpinClick.bind(this),
      () => {},
      this.onSpinMouseOut.bind(this),
      this.onSpinMouseDown.bind(this),
      this.onSpinRelease.bind(this),
    );

    this.rouletteContainer = new PIXI.Container();
    this.rouletteContainer.addChild(
      this.sign,
      this.wheel,
      this.flash,
      this.frameEffect,
      this.frame,
      this.spinButton,
      this.nice,
    );

    this.buttonContainer.parentLayer = layerBannerBlackBoard;
    this.addChild(this.rouletteContainer, this.meter, this.roundSpine, this.roundText, this.buttonContainer);
    this.wheelBone = this.wheel.skeleton.findBone('prg_rot');
    this.frameBone = this.frameEffect.skeleton.findBone('prg_rot_sub');

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.on(EventTypes.FISHING_CHANGE_WHEEL, this.changeWheelState.bind(this));
    eventManager.on(EventTypes.FISHING_WHEEL_SPIN_WAIT, this.waitSpinStart.bind(this));
    eventManager.on(EventTypes.FISHING_WHEEL_SPIN_START, this.startSpin.bind(this));
    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_END, this.onEndGotcha.bind(this));
    eventManager.on(EventTypes.SET_FISHING_CHALLENGE_TURBO_SPIN, this.setTurboSpin.bind(this));

    this.parentLayer = layerFishingChallenge;

    //const rot0 = this.wheelBone.rotation;
    this.wheel.state.addListener({
      start: (entry: TrackEntry) => {
        if (entry.animation?.name === 'pnl_rot' || entry.animation?.name === 'pnl_rot_over') {
          this.calcRotation();
        }
      },
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name === 'pnl_rot' || entry.animation?.name === 'pnl_rot_over') {
          this.endSpin();
        } else if (entry.animation?.name === 'start') {
          console.log();
        }
      },
      event: (_entry: TrackEntry, event: Event) => {
        if (this.wheelBone.rotation != null && event.data.name === 'changeRot') {
          this.wheelBone.rotation = this.rotationNum;

          const delay = Tween.createDelayAnimation(1000);
          delay.addOnComplete(() => {
            //cant short cut
            setIsSpinShortCut(true);
            this.wheel.state.timeScale = 1;
            this.frameEffect.state.timeScale = 1;
            this.sign.state.timeScale = 1;
          });
          delay.start();
        }
      },
    });

    this.frameEffect.state.addListener({
      event: (_entry: TrackEntry, event: Event) => {
        if (this.frameBone.rotation != null && event.data.name === 'changeRot') {
          this.frameBone.rotation = this.effectRotation;
        }
      },
    });

    this.sign.state.addListener({
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === 'nice') {
          this.onWheelNiceTiming();
          console.log('nice:');
        }
        if (event.data.name === 'miss') {
          AudioApi.play({ type: ISongs.XT005S_wheel_miss });
        }
      },
    });

    this.visible = false;
  }

  private calcRotation() {
    const WHEEL_PRIZE_CNT_MAX = 12;
    const WHEEL_ONE_PRIZE_ROT = -360 / WHEEL_PRIZE_CNT_MAX;
    const stopVariation = [-10, 0, 10];
    const adjustRotation = this.spinAnimationName === 'pnl_rot_over' ? -30 : 0;

    const destPos = (WHEEL_PRIZE_CNT_MAX - this.targetPos) % WHEEL_PRIZE_CNT_MAX;
    this.effectRotation = stopVariation[Math.floor(Math.random() * 3)]!;
    this.rotationNum = destPos * WHEEL_ONE_PRIZE_ROT + this.effectRotation + adjustRotation;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) {
    const fishRankToSkins: Record<FishKind, SpineInterface['fc_wheel']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    if (isFishingChallengeMode(settings.mode)) {
      this.buttonContainer.interactive = true;
      this.fishRank = settings.fishKind ?? 'Rank1';
      const skin = fishRankToSkins[this.fishRank];
      this.wheel.skeleton.setSkinByName(skin);
      this.wheel.state.addAnimation(0, 'pnl_wait', true);
      this.frame.skeleton.setSkinByName('wheel_1');
      this.sign.state.setAnimation(0, 'sgin_wait', false);
      this.spinButton.state.setAnimation(0, 'btn_def_eff', true);
      this.wheel.update(0);
      this.meter.visible = false;
      this.visible = true;

      this.updateRoundSpine(1, 3);
      this.roundSpine.visible = true;

      this.targetPos = 0;
      this.wheelSpinCnt = 0;
    } else {
      this.visible = false;
      this.wheel.state.setAnimation(0, 'pnl_wait', true);
      this.wheelSpinCnt = 0;
    }
  }

  private onSpinMouseDown() {
    this.spinButton.state.setEmptyAnimation(0);
    this.spinButton.state.setAnimation(0, 'btn_push', false);
  }

  private onSpinMouseOut() {
    if (this.spinState === 'spinning') {
      this.spinButton.state.setEmptyAnimation(0);
      this.spinButton.state.setAnimation(0, 'btn_def', true);
    } else {
      this.spinButton.state.setEmptyAnimation(0);
      this.spinButton.state.setAnimation(0, 'btn_def_eff', true);
    }
  }

  private onSpinClick() {
    //this.spinButton.state.setAnimation(0, 'btn_push', false);
    this.spinButton.state.addAnimation(0, 'btn_def', true);

    //AudioApi.play({ type: ISongs.XT005S_wheel_spin_push });

    eventManager.emit(EventTypes.USER_SPIN_BUTTON_CLICK);
  }
  private onSpinRelease() {}
  private waitSpinStart() {
    const wheelCount = this.wheelSpinCnt;
    //this.buttonContainer.interactive = false;

    //this.flash.state.setAnimation(0, 'pnl_change', false);

    if (wheelCount === 0) {
      this.frame.skeleton.setSkinByName('wheel_1');
    } else if (wheelCount === 1) {
      this.frame.skeleton.setSkinByName('wheel_2');
    } else if (wheelCount === 2) {
      this.frame.skeleton.setSkinByName('wheel_3');
    }

    this.roundSpine.state.setAnimation(0, 'systemfont_out', false);

    //const textOutDuration = PIXI.Loader.shared.resources['fc_wheel']?.spineData?.findAnimation('systemfont_out').duration;
    const textOutDuration = this.roundSpine.getAnimation(0, 'systemfont_out').duration;

    const textOutDelay = Tween.createDelayAnimation(textOutDuration);
    textOutDelay.addOnComplete(() => {
      this.roundSpine.visible = false;
    });
    textOutDelay.start();
  }

  private startSpin() {
    this.spinState = 'spinning';
    this.wheelSpinCnt++;
    this.spinButton.state.setAnimation(0, 'btn_def', true);

    const isSuccess = setFishingResult()?.fishingChallengeResult ? true : false;
    this.roundSpine.visible = false;

    setIsWheelSpinning(true);

    this.targetPos = setFishingResult()?.fishingChallengePosition!;

    const isPhoenix = getResultFromTbl(LotsFishingPhoenix, getRandomFromUUID(getBetData(setNextResult()!).id, 100)); // TO DO

    let waitDuration = 0;
    if (
      (this.fishRank === 'Rank1' || this.fishRank === 'Rank2' || this.fishRank === 'Rank3') &&
      isSuccess &&
      isPhoenix &&
      this.wheelSpinCnt === 3
    ) {
      eventManager.emit(EventTypes.PHOENIX_START);
      waitDuration = 3200;
    } else {
      waitDuration = 0;
    }
    const phoenixWait = Tween.createDelayAnimation(waitDuration);

    //this.spinAnimationName = Math.floor(Math.random() * 2) === 1 ? 'pnl_rot' : 'pnl_rot_over';
    this.spinAnimationName = 'pnl_rot';

    phoenixWait.addOnComplete(() => {
      this.wheel.state.setAnimation(0, this.spinAnimationName, false);

      this.frameEffect.state.setAnimation(0, 'frame_rot', false);

      this.frameEffect.visible = true;

      if (isSuccess) {
        this.sign.state.setAnimation(0, 'sgin_rot_nice', false);
      } else {
        this.sign.state.setAnimation(0, 'sgin_rot_miss', false);
      }
      this.nice.visible = false;

      AudioApi.play({ type: ISongs.XT005S_wheel_rotate });
    });
    phoenixWait.start();
  }

  private onWheelNiceTiming() {
    const isSuccess = setFishingResult()?.fishingChallengeResult ? true : false;
    if (isSuccess) {
      this.nice.state.setAnimation(0, 'nice_loop', true);
      this.nice.visible = true;
      this.meter.setWinCount(this.wheelSpinCnt);
      AudioApi.play({ type: ISongs.XT005S_wheel_nice });
    } else {
      this.nice.visible = false;
    }

    //AudioApi.play({ type: ISongs.XT005S_wheel_rotate_stop });

    eventManager.emit(EventTypes.FISHING_WHEEL_SPIN_STOP, isSuccess ? true : false);
  }

  private setTurboSpin() {
    this.sign.state.timeScale = FISHING_CHALLENGE_WHEEL_SKIP_SPEED;
    this.wheel.state.timeScale = FISHING_CHALLENGE_WHEEL_SKIP_SPEED;
    this.frameEffect.state.timeScale = FISHING_CHALLENGE_WHEEL_SKIP_SPEED;
    setIsSpinShortCut(true);

    AudioApi.stop({ type: ISongs.XT005S_wheel_rotate });
  }

  private endSpin() {
    this.spinState = 'wait';

    const wheelCount = this.wheelSpinCnt;
    const isSuccess = setFishingResult()?.fishingChallengeResult ? true : false;

    const delayDuration = isSuccess && wheelCount >= 3 ? 100 : 1000;
    const niceDispDelay = Tween.createDelayAnimation(delayDuration);

    this.wheel.state.addAnimation(0, 'pnl_wait', true);
    niceDispDelay.addOnComplete(() => {
      this.nice.visible = false;
      setIsSpinShortCut(false);

      if (isSuccess && wheelCount >= 3) {
        //this.meter.setWinCount(wheelCount);
        eventManager.emit(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.fishRank);
      } else if (isSuccess) {
        //this.meter.setWinCount(wheelCount);
        eventManager.emit(EventTypes.COUNT_UP_END);
        setIsWheelSpinning(false); //wait wheel change?
        this.buttonContainer.interactive = true;

        eventManager.emit(EventTypes.FISHING_CHANGE_WHEEL, wheelCount);
        this.spinButton.state.setAnimation(0, 'btn_def_eff', true);
      } else {
        transitionToBaseGame(() => {
          this.wheelBone.rotation = 0;
          setIsWheelSpinning(false);
        });
      }
    });
    niceDispDelay.start();
  }
  private changeWheelState(wheelCnt?: number) {
    this.meter.visible = true;

    if (wheelCnt === 0) {
      BgmControl.playBgm('challenge1', false, false);
      this.updateRoundSpine(1, 3);
      this.roundSpine.visible = true;
    } else if (wheelCnt === 1) {
      BgmControl.playBgm('challenge2', true, true);
      this.updateRoundSpine(2, 3);
      this.roundSpine.visible = true;
    } else if (wheelCnt === 2) {
      BgmControl.playBgm('challenge3', true, true);
      this.updateRoundSpine(3, 3);
      this.roundSpine.visible = true;
    }

    this.flash.state.setAnimation(0, 'pnl_change', false);

    if (wheelCnt === 0) {
      this.frame.skeleton.setSkinByName('wheel_1');
    } else if (wheelCnt === 1) {
      this.frame.skeleton.setSkinByName('wheel_2');
    } else if (wheelCnt === 2) {
      this.frame.skeleton.setSkinByName('wheel_3');
    }
  }

  private onEndGotcha() {
    this.visible = false;
    setIsWheelSpinning(false);
  }

  private updateRoundSpine(cnt: number, max: number) {
    this.roundText.text = i18n.t('fishing.challenge.round', { cnt: cnt, max: max });
    this.roundText.updateText(false);
    this.roundSpine.state.setAnimation(0, 'systemfont', true);
    requestAnimationFrame(() => {
      this.roundSpine.hackTextureBySlotName('blank', this.roundText.texture, this.roundText.texture.frame);
    });
    AudioApi.play({ type: ISongs.XT005S_challenge_round });
  }
  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? gameLayout.fishingChallenge.Landscape : gameLayout.fishingChallenge.PortRait;

    this.rouletteContainer.position.copyFrom(layout.wheelContainer);
    this.rouletteContainer.scale.set(layout.wheelContainer.scale);
    this.roundSpine.position.copyFrom(layout.roundText);
    this.roundText.style = layout.roundText.style;
    this.roundText.updateText(false);

    this.buttonContainer.position.copyFrom(layout.ButtonContainer);
  };
}

export default FishingChallenge;
