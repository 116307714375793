import { GameMode } from '../../consts';
import { isFreeSpinsGameMode } from '../../utils';
import ViewContainer from '../components/container';
import { layerGameTitle } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

class GameTitle extends ViewContainer {
  private title: TickerSpine<'title_logo'>;

  private layout: 'portrait' | 'landscape';
  constructor() {
    super();

    this.layout = 'landscape';
    this.title = new TickerSpine('title_logo');

    this.addChild(this.title);

    this.title.state.setAnimation(0, 'base', true);
    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerGameTitle;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    if (isFreeSpinsGameMode(settings.mode)) {
      this.title.visible = false;
    } else {
      if (this.layout === 'portrait') {
        this.title.visible = true;
      } else {
        this.title.visible = true;
      }
    }
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.layout = 'landscape';
      this.title.state.setAnimation(0, 'base', true);
      this.title.position.copyFrom(gameLayout.gameTitle.landscape);
      this.title.scale.set(gameLayout.gameTitle.landscape.scale);
      this.title.visible = true;
    } else {
      this.layout = 'portrait';
      this.title.state.setAnimation(0, 'base', true);
      this.title.position.copyFrom(gameLayout.gameTitle.portrait);
      this.title.scale.set(gameLayout.gameTitle.portrait.scale);
      this.title.visible = true;
    }
  };
}

export default GameTitle;
