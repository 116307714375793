import { useReactiveVar } from '@apollo/client';
import FontFaceObserver from 'fontfaceobserver';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  PRELOAD_SPINE_TEXTURES,
  SPINE_ATLASES,
  SPINE_LOADER_TEXTURES,
  Variables,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import { IAuthInput } from '../../global.d';
import { setIsMobile, setIsSoundOn, setIsTurboSpin, setProgress } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { EventTypes, eventManager } from '../../slotMachine/config';
import { addTextureCacheFromSpineAtlas, loadErrorHandler, loadPixiAssets, parseQuery, wait } from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import { createBitmapFont } from './createBitmapFont';
import styles from './loadScreen.module.scss';
import { useAuth } from './useAuth';
import { useGameSetting } from './useGameSetting';
import { useLastBet } from './useLastBet';
import { useRestoreState } from './useRestoreState';
import { useUserBalance } from './useUserBalance';

const LoadScreen: React.FC = () => {
  const progress = useReactiveVar(setProgress);
  const [isShowContent, setShowContent] = useState(true);
  const { getAuth } = useAuth();
  const { getUserBalance } = useUserBalance();
  const { checkBrokenGame } = useRestoreState();
  const { getLastBet } = useLastBet();
  const { getGameSettingsData } = useGameSetting();

  useEffect(() => {
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        // TODO : remove this
        const { worker } = await import('../../__mock__/browser');
        await worker.start({ onUnhandledRequest: 'bypass' });

        setIsMobile(isMobile);
        const { token, clientId } = parseQuery<IAuthInput>();
        const { data } = await getAuth({ variables: { input: { connectToken: token, clientId } } });

        await remoteStorage.init(data!.auth!.sessionId);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache<IConfig>('config', {
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getLastBet();
        await checkBrokenGame();
        await getGameSettingsData();

        //await getSlotData();
        //await getLastBetFromBonusBetId();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(SPINE_ATLASES, process.env.PUBLIC_URL);
        await loadPixiAssets(
          [
            ...SPINE_LOADER_TEXTURES(setIsMobile()),
            ...PRELOAD_SPINE_TEXTURES,
            ...LOADER_SPRITE_TEXTURES,
            ...LOADER_TEXTURES,
          ],
          process.env.PUBLIC_URL,
        );

        addTextureCacheFromSpineAtlas('spine/');

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          isSoundEnabled: setIsSoundOn(),
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: global.__ENV__.ENV || Environments.DEVELOPMENT,
          onSuspended: () => {},
          restricted: true,
        }).then(() => {
          eventManager.emit(EventTypes.SOUND_INITIALIZED);
        });
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(99, ELoaderStages.ASSETS, async (stage) => {
        const fontNoto = new FontFaceObserver(Variables.FONT_FAMILY, { weight: 700 });
        await fontNoto.load();
        await createBitmapFont();
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, async () => {
          setProgress({
            ...setProgress(),
            wasLoaded: false,
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
        <div className={styles.companyLogo_loaded} style={{ height: `${progress.status}%` }}>
          <img draggable="false" alt="logoLoaded" src={Resources.getSource(ResourceTypes.logo)} />
        </div>
      </div>
    </div>
  );
};

export default LoadScreen;
