import {
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
} from '../config';

import { fishingChallengeRoundPortraitTextStyles, fishingChallengeRoundTextStyles } from './textStyle';

export const FISHING_PRIZE_POS = { x: 960 + SAFE_AREA_LANDSCAPE_PIVOT_X, y: 540 + SAFE_AREA_LANDSCAPE_PIVOT_Y };
export const FISHING_PRIZE_PORTRAIT_POS = { x: 606 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 1078 + SAFE_AREA_PORTRAIT_PIVOT_Y };

export const FISHING_CHALLENGE_WHEEL_SKIP_SPEED = 3.0;

export const FishingLayout = {
  Banner: {
    LandScape: {
      Base: { x: 401, y: 459 },
      BaseScale: 1028 / 1210,
      title: { x: 3, y: -528 },
      titleScale: 764 / 1092 / (1028 / 1210),
    },
    Portrait: {
      Base: { x: 441, y: 429 },
      BaseScale: 1,
      title: { x: 3, y: -528 },
      titleScale: 1,
    },
  },
  Challenge: {
    Scale: 880 / 1023,
    Container: {
      Landscape: { x: 326 - 442, y: 436 - 385 },
      PortRait: { x: 323 - 1027 / 2, y: 353 - 896 / 2 },
    },
    Wheel: {
      Base: { x: 623, y: 453 },
      ResultText: { x: 623, y: 453 },
    },
    Sign: {
      Base: { x: 109, y: 484 },
      TextNice: { x: 107, y: 70 + 330 },
      TextMiss: { x: 107, y: 237 + 330 },
    },
    Meter: {
      landscape: {
        Container: { x: 396, y: 19 },
        textBaseContainer: { x: 432, y: 108 },
        Title: { x: -351, y: 0 },
        Rank: { x: -125, y: 0 },
        Name: { x: 129, y: 0 },
        scale: 478 / 870,
      },
      Portrait: {
        Container: { x: 422, y: -319 },
        textBaseContainer: { x: 424, y: -164 },
        Title: { x: -392, y: 0 },
        Rank: { x: -134, y: 0 },
        Name: { x: 150, y: 0 },
        scale: 1,
      },
    },
    Round: {
      landscape: {
        textStyle: fishingChallengeRoundTextStyles,
        pos: { x: 424, y: 326 },
      },
      Portrait: {
        textStyle: fishingChallengeRoundPortraitTextStyles,
        pos: { x: 436, y: 215 },
      },
    },
    Button: { x: 623, y: 453 },
    ButtonContainer: {
      landscape: { x: 423, y: 503 },
      portrait: { x: 423, y: 413 },
    },
  },
  GambleSelect: {
    common: {
      base: { x: 0, y: 0 },
      bannerL: { x: -280, y: -88 },
      bannerR: { x: 280, y: -88 },
      iconL: { x: 0, y: 0 },
      iconR: { x: 0, y: 0 },
      textL: { x: -288, y: -382 },
      textR: { x: 288, y: -382 },
      description: { x: 0, y: 264 },
      rankTitleL: { x: -117, y: -296 },
      rankTitleR: { x: 442, y: -289 },
      rankL: { x: -116, y: -249 },
      rankR: { x: 450, y: -236 },
      multiplierDescL: { x: -283, y: 125 },
      multiplierDescR: { x: 278, y: 125 },
      multiplierL: { x: -284, y: 190 },
      multiplierR: { x: 276, y: 190 },
      gambleBtnArea: { x: -547, y: -430, w: 534, h: 686 },
      collectBtnArea: { x: 12, y: -430 / 2, w: 534, h: 686 },
      cancelBtn: { x: -284, y: 352 },
      okBtn: { x: 269, y: 352 },
    },
  },
  Gamble: {
    gambleStartBtnArea: { x: -2000 / 2, y: -2000 / 2, w: 4000, h: 4000 },
    landscape: {
      icon: { x: 441, y: 430 },
      title: { x: 427, y: -14 },
      Sign: { x: -443, y: 483 },
      meter: { x: 910, y: 387 },
      miss: { x: 401, y: 413 },
      start: { x: -70, y: 813 },
      lose: { x: 732 + 417 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X, y: 119 + 232 / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y },
      crimax_bg: { x: 360, y: 427 },
      crimaxA_fish: { x: 329, y: 622 },
      crimaxB_fish: { x: 341, y: 709 },
      crimax_line: { x: 360, y: 667 },
      scale: {
        line: 1940 / 2156,
        crimaxB: 1260 / 1800,
        title: 1028 / 1210,
        icon: 1028 / 1210,
        meter: 126 / 180,
        crimaxA: 840 / 1200,
        lose: 417 / 588,
      },
    },
    portrait: {
      title: { x: 404, y: -241 },
      icon: { x: 411, y: 365 },
      Sign: { x: -51, y: 424 },
      meter: { x: 830 + 347 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 222 + 1056 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y },
      miss: { x: 462, y: 339 },
      start: { x: 412, y: 581 },
      lose: { x: 276 + 588 / 2 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 420 + 279 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y },
      crimax_bg: { x: 412, y: 581 },
      crimaxA_fish: { x: 412, y: 581 },
      crimaxB_fish: { x: 412, y: 581 },
      crimax_line: { x: 412, y: 581 },
      scale: {
        line: 1,
        title: 1,
        icon: 1,
        meter: 347 / 404,
        crimaxA: 1,
        crimaxB: 1,
        lose: 1,
      },
    },
  },
  Gotcha: {
    landscape: {
      bg: { x: 360, y: 503 },
      bgScale: 1920 / 2000,
      back: { x: 360, y: 503 },
      prize: { x: 356, y: 455 },
      prizeScale: 1190 / 1400,
      front: { x: 360, y: 503 },
    },
    portrait: {
      bg: { x: 411, y: 581 },
      bgScale: 2156 / 2000,
      back: { x: 411, y: 581 },
      prize: { x: 476, y: 343 },
      prizeScale: 1190 / 1400,
      front: { x: 411, y: 581 },
    },
  },
};
