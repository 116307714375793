import { Event, TrackEntry } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { FishKind, ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import i18n from '../../i18next';
import { isBaseGameMode, isFishingChallengeMode } from '../../utils';
//import Tween from '../animations/tween';
import AutoResizeText from '../components/autoResizeText';
import { SpriteInteractiveButton } from '../components/button';
import ButtonContainer from '../components/buttonContainer';
import ViewContainer from '../components/container';
import { layerFishingPrize } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

import { FishingLayout } from './config';
import { fishToMultiplier, fishToRank } from './icon/config';
//import { FishIcon } from './icon/fishIcon';
import {
  gambleSelectDescriptionTextStyles,
  gambleSelectMultiplierDescTextStyles,
  gambleSelectMultiplierTextStyles,
  gambleSelectRankTextStyles,
  gambleSelectRankTitleTextStyles,
  gambleSelectTextStyles,
  gambleSelectTitleTextStyles,
} from './textStyle';

type GambleSelectStateType = 'select' | 'gamble' | 'collect' | 'gambleOk' | 'collectOk';

class GambleSelect extends ViewContainer {
  private base: TickerSpine<'gamble'>;
  private bannerL: TickerSpine<'gamble'>;
  private bannerR: TickerSpine<'gamble'>;

  private offL: TickerSpine<'gamble'>;
  private offR: TickerSpine<'gamble'>;

  private iconL: TickerSpine<'gamble'>;
  private iconR: TickerSpine<'gamble'>;

  private rankTitleTextL: AutoResizeText;
  private rankTitleTextR: AutoResizeText;

  private rankTextL: AutoResizeText;
  private rankTextR: AutoResizeText;

  private multiplierDescTextL: AutoResizeText;
  private multiplierDescTextR: AutoResizeText;

  private multiplierTextL: AutoResizeText;
  private multiplierTextR: AutoResizeText;

  private textGamble: AutoResizeText;
  private textCollect: AutoResizeText;

  private title: AutoResizeText;
  private description: AutoResizeText;

  private container: PIXI.Container;

  private gambleContainerBtn: PIXI.Container;
  private collectContainerBtn: PIXI.Container;

  private layout: 'portrait' | 'landscape';

  private state: GambleSelectStateType = 'select';

  private collectRank: FishKind;
  private gambleRank: FishKind;

  private okBtn: SpriteInteractiveButton;
  private cancelBtn: SpriteInteractiveButton;

  constructor() {
    super();

    this.layout = 'landscape';
    this.state = 'select';
    this.collectRank = 'Rank7';
    this.gambleRank = 'Rank6';

    this.base = new TickerSpine('gamble');
    this.base.state.setAnimation(0, 'base', true);

    this.bannerL = new TickerSpine('gamble');
    this.bannerL.state.setAnimation(0, 'banner_l', true);

    this.bannerR = new TickerSpine('gamble');
    this.bannerR.state.setAnimation(0, 'banner_r', true);

    this.offL = new TickerSpine('gamble');
    this.offL.state.setAnimation(0, 'off', true);
    this.offL.visible = false;

    this.offR = new TickerSpine('gamble');
    this.offR.state.setAnimation(0, 'off', false);
    this.offR.visible = false;

    this.iconL = new TickerSpine('gamble');
    this.iconL.state.setAnimation(0, 'prize_l_pt06', true);

    this.iconR = new TickerSpine('gamble');
    this.iconR.state.setAnimation(0, 'prize_r_pt07', true);

    this.rankTitleTextL = new AutoResizeText(i18n.t('freeSpinStartBanner.rankTitle'), gambleSelectRankTitleTextStyles);
    this.rankTitleTextL.anchor.set(0.5);

    this.rankTitleTextR = new AutoResizeText(i18n.t('freeSpinStartBanner.rankTitle'), gambleSelectRankTitleTextStyles);
    this.rankTitleTextR.anchor.set(0.5);

    this.rankTextL = new AutoResizeText(fishToRank['Rank6'], gambleSelectRankTextStyles);
    this.rankTextL.anchor.set(0.5);

    this.rankTextR = new AutoResizeText(fishToRank['Rank7'], gambleSelectRankTextStyles);
    this.rankTextR.anchor.set(0.5);

    this.multiplierDescTextL = new AutoResizeText(
      i18n.t('freeSpinStartBanner.description'),
      gambleSelectMultiplierDescTextStyles,
    );
    this.multiplierDescTextL.anchor.set(0.5);

    this.multiplierDescTextR = new AutoResizeText(
      i18n.t('freeSpinStartBanner.description'),
      gambleSelectMultiplierDescTextStyles,
    );
    this.multiplierDescTextR.anchor.set(0.5);

    this.multiplierTextL = new AutoResizeText(`x${fishToMultiplier['Rank6']}`, gambleSelectMultiplierTextStyles);
    this.multiplierTextL.anchor.set(0.5);

    this.multiplierTextR = new AutoResizeText(`x${fishToMultiplier['Rank7']}`, gambleSelectMultiplierTextStyles);
    this.multiplierTextR.anchor.set(0.5);

    this.textGamble = new AutoResizeText(i18n.t('fishing.gambleSelect.gamble'), gambleSelectTextStyles);
    this.textGamble.anchor.set(0.5);

    this.textCollect = new AutoResizeText(i18n.t('fishing.gambleSelect.collect'), gambleSelectTextStyles);
    this.textCollect.anchor.set(0.5);

    this.okBtn = this.initOkBtn();

    this.gambleContainerBtn = new ButtonContainer(
      FishingLayout.GambleSelect.common.gambleBtnArea.x,
      FishingLayout.GambleSelect.common.gambleBtnArea.y,
      FishingLayout.GambleSelect.common.gambleBtnArea.w,
      FishingLayout.GambleSelect.common.gambleBtnArea.h,
      this.onGambleClick.bind(this),
    );

    this.collectContainerBtn = new ButtonContainer(
      FishingLayout.GambleSelect.common.collectBtnArea.x,
      FishingLayout.GambleSelect.common.collectBtnArea.y,
      FishingLayout.GambleSelect.common.collectBtnArea.w,
      FishingLayout.GambleSelect.common.collectBtnArea.h,
      this.onCollectClick.bind(this),
    );

    this.description = new AutoResizeText(
      i18n.t('fishing.gambleSelect.description'),
      gambleSelectDescriptionTextStyles,
    );
    this.description.anchor.set(0.5, 0);

    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();

    this.container = new PIXI.Container();
    this.container.addChild(
      this.base,
      this.bannerL,
      this.bannerR,
      this.textGamble,
      this.textCollect,
      this.multiplierDescTextL,
      this.multiplierDescTextR,
      this.multiplierTextL,
      this.multiplierTextR,
      this.iconR,
      this.rankTitleTextR,
      this.rankTextR,
      this.iconL,
      this.rankTitleTextL,
      this.rankTextL,
      this.offL,
      this.offR,
      this.description,
      this.gambleContainerBtn,
      this.collectContainerBtn,

      this.cancelBtn,
      this.okBtn,
    );

    this.title = new AutoResizeText(i18n.t('fishing.gambleSelect.title'), gambleSelectTitleTextStyles);
    this.title.anchor.set(0.5);

    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.anchor.set(0.5);
    backdrop.tint = 0x000000;
    backdrop.alpha = 0.7;
    backdrop.width = 8000;
    backdrop.height = 8000;

    this.addChild(backdrop, this.container, this.title);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.on(EventTypes.FISHING_GAMBLE_SELECT_START, this.start.bind(this));
    eventManager.addListener(EventTypes.FISHING_SCALING_START, this.hide.bind(this));

    this.parentLayer = layerFishingPrize;

    this.iconL.state.addListener({
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === 'erase') {
          this.iconR.visible = false;
        }
      },
    });

    this.initContainerPositions();

    this.visible = false;
    //this.start('Rank3');
  }

  private setState(state: GambleSelectStateType) {
    this.state = state;
    this.updateVisible();
  }

  private updateVisible() {
    this.cancelBtn.visible = true;
    this.okBtn.visible = true;
    this.description.visible = true;
    this.rankTextL.visible = true;
    this.rankTitleTextL.visible = true;
    this.multiplierDescTextL.visible = true;
    this.multiplierDescTextR.visible = true;
    this.multiplierTextL.visible = true;
    this.multiplierTextR.visible = true;

    switch (this.state) {
      case 'select': {
        this.offL.visible = false;
        this.offR.visible = false;
        this.cancelBtn.visible = false;
        this.okBtn.visible = false;
        this.description.visible = true;
        break;
      }
      case 'gamble': {
        this.offR.visible = true;
        this.offL.visible = false;
        this.description.visible = false;
        this.okBtn.visible = true;
        this.cancelBtn.visible = true;
        break;
      }
      case 'collect': {
        this.offL.visible = true;
        this.offR.visible = false;
        this.description.visible = false;
        this.okBtn.visible = true;
        this.cancelBtn.visible = true;
        break;
      }
      case 'collectOk': {
        this.offL.visible = true;
        this.offR.visible = false;
        this.description.visible = false;

        break;
      }
      case 'gambleOk': {
        this.offL.visible = false;
        this.offR.visible = true;
        this.description.visible = false;
        this.okBtn.visible = true;
        this.cancelBtn.visible = true;

        //this.rankTextL.visible = false;
        //this.rankTitleTextL.visible = false;
        //this.multiplierDescTextL.visible = false;
        //this.multiplierDescTextR.visible = false;
        //this.multiplierTextL.visible = false;
        //this.multiplierTextR.visible = false;
        break;
      }
    }
  }
  private initContainerPositions() {
    const commonPos = FishingLayout.GambleSelect.common;
    this.base.position.copyFrom(commonPos.base);
    this.bannerL.position.copyFrom(commonPos.bannerL);
    this.bannerR.position.copyFrom(commonPos.bannerR);
    this.offL.position.copyFrom(commonPos.bannerL);
    this.offR.position.copyFrom(commonPos.bannerR);
    this.iconL.position.copyFrom(commonPos.iconL);
    this.iconR.position.copyFrom(commonPos.iconR);
    this.textGamble.position.copyFrom(commonPos.textL);
    this.textCollect.position.copyFrom(commonPos.textR);
    this.rankTitleTextL.position.copyFrom(commonPos.rankTitleL);
    this.rankTitleTextR.position.copyFrom(commonPos.rankTitleR);
    this.rankTextL.position.copyFrom(commonPos.rankL);
    this.rankTextR.position.copyFrom(commonPos.rankR);
    this.multiplierDescTextL.position.copyFrom(commonPos.multiplierDescL);
    this.multiplierDescTextR.position.copyFrom(commonPos.multiplierDescR);
    this.multiplierTextL.position.copyFrom(commonPos.multiplierL);
    this.multiplierTextR.position.copyFrom(commonPos.multiplierR);

    this.description.position.copyFrom(commonPos.description);
    this.okBtn.position.copyFrom(commonPos.okBtn);
    this.cancelBtn.position.copyFrom(commonPos.cancelBtn);
  }
  private initCancelBtn(): SpriteInteractiveButton {
    const cancelBtn = new SpriteInteractiveButton('gamble_btn_back_def', false, 158 / 182, () => {
      this.cancelBtn.setDisable(false);
      this.gambleContainerBtn.interactive = true;
      this.collectContainerBtn.interactive = true;
      eventManager.emit(EventTypes.FISHING_G_SELECT_SELECTED, false);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      this.selectView();
    });
    cancelBtn.visible = false;
    return cancelBtn;
  }

  private initOkBtn(): SpriteInteractiveButton {
    const okBtn = new SpriteInteractiveButton('gamble_btn_ok_def', true, 158 / 182, () => {
      okBtn.setDisable(true);
      this.cancelBtn.setDisable(true);
      AudioApi.play({ type: ISongs.XT005S_choice_select });

      if (this.state === 'gamble') {
        this.setState('gambleOk');

        //this.iconL.state.setAnimation(0, this.getIconLMoveAnim(this.gambleRank), false);
        //const delay = Tween.createDelayAnimation(833);

        //delay.addOnComplete(() => {
        eventManager.emit(EventTypes.GAMBLE_SELECTED, this.gambleRank);
        //});
        //delay.start();
      } else {
        this.setState('collectOk');
        eventManager.emit(EventTypes.GAMBLE_CANCEL_SELECTED, this.collectRank);
      }
    });

    okBtn.visible = false;
    return okBtn;
  }

  private onGambleClick() {
    this.gambleContainerBtn.interactive = true;
    this.collectContainerBtn.interactive = true;

    this.setState('gamble');

    eventManager.emit(EventTypes.FISHING_G_SELECT_SELECTED, true);
    AudioApi.play({ type: ISongs.XT005S_choice });
  }

  private onCollectClick() {
    this.gambleContainerBtn.interactive = true;
    this.collectContainerBtn.interactive = true;

    this.setState('collect');
    eventManager.emit(EventTypes.FISHING_G_SELECT_SELECTED, true);
    AudioApi.play({ type: ISongs.XT005S_choice });
  }

  private selectView() {
    this.setState('select');
  }

  private hide() {
    this.visible = false;
  }

  private start(fishRank: FishKind) {
    this.iconR.visible = true;
    this.iconL.visible = true;

    this.collectRank = fishRank;
    this.okBtn.setDisable(false);
    this.cancelBtn.setDisable(false);
    this.gambleContainerBtn.interactive = true;
    this.collectContainerBtn.interactive = true;
    this.offL.visible = false;
    this.offR.visible = false;
    this.cancelBtn.visible = false;
    this.okBtn.visible = false;
    this.description.visible = true;
    this.rankTextL.visible = true;
    this.rankTitleTextL.visible = true;
    this.multiplierTextL.visible = true;
    this.multiplierTextR.visible = true;
    this.multiplierDescTextL.visible = true;
    this.multiplierDescTextR.visible = true;

    if (this.layout === 'portrait') {
      this.title.visible = true;
    }

    this.setFishRank();
    this.visible = true;
  }

  private getIconLWaitAnim(fishKind: FishKind): SpineInterface['gamble']['animations'] {
    const Animation: Record<FishKind, SpineInterface['gamble']['animations']> = {
      Rank1: 'prize_l_pt01',
      Rank2: 'prize_l_pt02',
      Rank3: 'prize_l_pt03',
      Rank4: 'prize_l_pt04',
      Rank5: 'prize_l_pt05',
      Rank6: 'prize_l_pt06',
      Rank7: 'prize_l_pt06',
    };
    return Animation[fishKind]!;
  }
  private getIconRWaitAnim(fishKind: FishKind): SpineInterface['gamble']['animations'] {
    const Animation: Record<FishKind, SpineInterface['gamble']['animations']> = {
      Rank1: 'prize_r_pt02',
      Rank2: 'prize_r_pt02',
      Rank3: 'prize_r_pt03',
      Rank4: 'prize_r_pt04',
      Rank5: 'prize_r_pt05',
      Rank6: 'prize_r_pt06',
      Rank7: 'prize_r_pt07',
    };
    return Animation[fishKind]!;
  }
  /*
  private getIconLMoveAnim(fishKind: FishKind): SpineInterface['gamble']['animations'] {
    const Animation: Record<FishKind, SpineInterface['gamble']['animations']> = {
      Rank1: 'prize_l_move_pt01',
      Rank2: 'prize_l_move_pt02',
      Rank3: 'prize_l_move_pt03',
      Rank4: 'prize_l_move_pt04',
      Rank5: 'prize_l_move_pt05',
      Rank6: 'prize_l_move_pt06',
      Rank7: 'prize_l_move_pt06',
    };
    return Animation[fishKind]!;
  }
    */
  private setFishRank() {
    const fishUpgradeTable: Record<FishKind, FishKind> = {
      Rank1: 'Rank1',
      Rank2: 'Rank1',
      Rank3: 'Rank2',
      Rank4: 'Rank3',
      Rank5: 'Rank4',
      Rank6: 'Rank5',
      Rank7: 'Rank6',
    };

    const nextRank = fishUpgradeTable[this.collectRank];
    this.gambleRank = nextRank;

    this.iconL.state.setAnimation(0, this.getIconLWaitAnim(this.gambleRank), true);
    this.iconR.state.setAnimation(0, this.getIconRWaitAnim(this.collectRank), true);

    this.rankTextL.text = fishToRank[this.gambleRank];
    this.rankTextR.text = fishToRank[this.collectRank];

    this.multiplierTextL.text = `x${fishToMultiplier[this.gambleRank]}`;
    this.multiplierTextR.text = `x${fishToMultiplier[this.collectRank]}`;

    //this.iconL.setFishKind(this.gambleRank);
    //this.iconR.setFishKind(this.collectRank);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (isFishingChallengeMode(settings.mode)) {
      this.visible = false;
    } else if (isBaseGameMode(settings.mode)) {
      this.selectView();
      this.gambleContainerBtn.interactive = false;
      this.collectContainerBtn.interactive = false;
      this.visible = false;
    } else if (settings.mode === GameMode.SCALING) {
      //this.visible = true;
      this.gambleContainerBtn.interactive = false;
      this.collectContainerBtn.interactive = false;
    } else {
      this.visible = false;
    }
  }

  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? gameLayout.gambleSelect.landscape : gameLayout.gambleSelect.portrait;

    this.container.scale.set(layout.container.scale);

    this.title.position.copyFrom(layout.title);
    this.container.position.copyFrom(layout.container);

    if (width > height) {
      this.layout = 'landscape';
      this.title.visible = false;
    } else {
      this.layout = 'portrait';
      this.title.visible = true;
    }
  };
}

export default GambleSelect;
